import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  Button,
  message,
  Select,
  notification,
  Input,
  Switch,
  Row,
  Col,
  Spin,
} from "antd";
import { companyState } from "../../../lib/stores/companyId";
import "./page.css";
import { MenuState } from "../../../lib/stores/menuId";

const MenuItemForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { itemId } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  const [taxList, setTaxList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isStartLoading, setIsStartLoading] = useState(true);
  const [menuList, setMenuList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [variantList, setVariantList] = useState([]);

  const [selectMenu, setSelectMenu] = useState(null);
  const [selectItem, setSelectItem] = useState(null);
  const [selectVariant, setSelectVariant] = useState(null);
  const [menuItemsList, setMenuItemsList] = useState([]);
  const [dynamicMenuId, setDynamicMenuId] = useRecoilState(MenuState);

  useEffect(() => {
    setBreadCrumb([
      { label: "Menu Master", href: "/dashboard" },
      { label: "Menu Item Price", href: "/menu-item-pricem" },
      {
        label: itemId ? "Edit Menu Item Price" : "Add Menu Item Price",
        href: itemId ? `/menu-item-price/${itemId}` : "/menu-item-price/add",
      },
    ]);
  }, [setBreadCrumb]);

  // In the useEffect that handles form initialization
  useEffect(() => {
    if (!location.pathname.includes("add")) {
      setTimeout(() => {
        fetchById();
      }, 1000);
    } else {
      setTimeout(() => {
        setIsStartLoading(false);
      }, 1000);
    }
  }, [location]);

  const fetchById = async () => {
    try {
      setIsStartLoading(true);
      const response = await get(`${apiEndpoints.menuitempricegud}/${itemId}`);
      if (response.status === 200) {
        const initialValues = {
          ...response?.data,
          menuId: response?.data?.menuId, // Ensure correct menuId is set here
          itemId: response?.data?.itemId,
          variantId: response?.data?.itemVariantId,
        };
        form.setFieldsValue(initialValues);
        setSelectMenu(response?.data?.menuId); // Correctly set selectMenu
        setSelectItem(response?.data?.itemId);
      }
    } catch (error) {
      console.log(error, "error");
      notification.error({
        message: error?.response?.data?.message || "Error",
      });
    } finally {
      setIsStartLoading(false);
    }
  };

  const fetchMenus = async () => {
    try {
      const response = await get(apiEndpoints.menus);
      console.log(response, "API response for menus"); // Add this to verify the API response
      if (response.status === 200) {
        const menus = response?.data;
        console.log(menus, "Fetched Menus"); // Check if menus are fetched correctly
        setMenuList(menus); // Set the menu list for rendering

        if (menus.length > 0) {
          setTimeout(() => {
            if (location.pathname.includes("add")) {
              setSelectMenu(dynamicMenuId); // Make sure to set `menuId` correctly (fixed typo)
              form.setFieldsValue({ menuId: dynamicMenuId }); // Set the form field value correctly (fixed typo)
            }
            fetchMenuItems(dynamicMenuId); // Fetch items for the selected menu
          }, 1000);
        }
      }
    } catch (error) {
      console.error(error, "Error while fetching menus"); // Improved error logging
      message.error("Error while fetching menus");
    }
  };

  const fetchItems = async () => {
    try {
      const response = await get(apiEndpoints.getAllItems);
      if (response.status === 200) {
        const items = response?.data;
        setItemList(items);
        if (items.length > 0) {
          if (location.pathname.includes("add")) {
            setSelectItem(items[0]?.itemId);
            form.setFieldsValue({ itemId: items[0]?.itemId });
          }
          await fetchVariants(items[0]?.itemId); // Fetch the variants for the first item
        }
      }
    } catch (error) {
      message.error("Error while fetching items");
    }
  };

  const fetchVariants = async (itemId) => {
    try {
      const response = await get(`${apiEndpoints.variant}/${itemId}`);
      if (response.status === 200) {
        const variants = response?.data;
        setVariantList(variants);
        if (variants.length > 0) {
          if (location.pathname.includes("add")) {
            setSelectVariant(variants[0]?.variantId);
          }
          form.setFieldsValue({ variantId: variants[0]?.variantId });
          form.setFieldsValue({ unitPrice: variants[0]?.unitPrice });
          form.setFieldsValue({ variantSize: variants[0]?.variantSize });
          form.setFieldsValue({ taxRate: variants[0]?.taxRate });
          form.setFieldsValue({ taxInclusive: variants[0]?.taxInclusive });
        }
      }
    } catch (error) {
      message.error("Error while fetching variants");
    }
  };

  useEffect(() => {
    // taxInclusive, taxRate, variantSize  not present in get
    if (selectVariant) {
      const variantsRecord = variantList.filter(
        (item) => item.variantId === selectVariant
      );
      form.setFieldsValue({
        variantSize: variantsRecord[0]?.variantSize,
      });
      form.setFieldsValue({ taxRate: variantsRecord[0]?.taxRate });
      form.setFieldsValue({ taxInclusive: variantsRecord[0]?.taxInclusive });
    }
  }, [selectVariant]);

  const fetchMenuItems = async (id) => {
    try {
      const response = await get(`${apiEndpoints.menuitemprice}/${id}`);
      if (response?.status === 200) {
        setMenuItemsList(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    fetchMenus();
    fetchItems();
  }, []);

  const handleMenuChange = async (value) => {
    setSelectMenu(value);
    setDynamicMenuId(value);
    await fetchMenuItems(value);
  };

  const handleItemChange = async (value) => {
    setSelectItem(value);
    await fetchVariants(value);
  };

  const handleVariantChange = async (value) => {
    setSelectVariant(value);
    const variants = variantList.filter((item) => item.variantId === value);
    form.setFieldsValue({ variantId: variants[0]?.variantId });
    form.setFieldsValue({ variantSize: variants[0]?.variantSize });
    form.setFieldsValue({ unitPrice: variants[0]?.unitPrice });
    form.setFieldsValue({ taxRate: variants[0]?.taxRate });
    form.setFieldsValue({ taxInclusive: variants[0]?.taxInclusive });
  };

  const onFinish = async (values: any) => {
    try {
      const payLoad = {
        itemVariantId: values?.variantId,
        sortId: menuItemsList?.length > 0 ? menuItemsList?.length + 1 : 1,
        ...values,
      };
      setIsLoading(true);
      const endPoints = {
        updateDevice: "menuitempricegud",
        addDevice: "menuitempricegud",
      };

      const endPoint = itemId ? endPoints.updateDevice : endPoints.addDevice;
      const method = itemId ? put : post;

      const apiUrl = itemId
        ? `${apiEndpoints[endPoint]}/${itemId}`
        : apiEndpoints[endPoint];

      const response = await method(apiUrl, payLoad);
      if (response.status === 200) {
        notification.success({
          message: itemId ? "Updated Successfully" : "Created Successfully",
        });
        navigate("/menu-item-price");
      } else {
        notification.error({
          message: response?.message || "Error",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
      notification.error({
        message: error?.response?.data?.message || "Error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.error({
      message: "Please fill in all fields",
    });
  };

  return (
    <>
      {isStartLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Center vertically
            height: "50vh", // Full height of the viewport
          }}
        >
          <Spin />
        </div>
      ) : (
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={{}}
          className="item-form"
        >
          {/* Use Row and Col for layout */}
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="menuId"
                label="Select Menu"
                rules={[{ required: true, message: "Please select a menu!" }]}
              >
                <Select
                  onChange={handleMenuChange}
                  placeholder="Select Menu"
                  value={selectMenu} // Make sure the value is properly set for selected menu
                >
                  {menuList.map((menu) => (
                    <Select.Option key={menu?.menuId} value={menu?.menuId}>
                      {menu.menuName} {/* Display menuName */}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="itemId"
                label="Select Item"
                rules={[{ required: true, message: "Please select an item!" }]}
              >
                <Select
                  onChange={handleItemChange}
                  placeholder="Select Item"
                  value={selectItem}
                >
                  {itemList.map((item) => (
                    <Select.Option key={item?.itemId} value={item?.itemId}>
                      {item.itemName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="variantId"
                label="Select Variant"
                rules={[
                  { required: true, message: "Please select a variant!" },
                ]}
              >
                <Select
                  onChange={handleVariantChange}
                  placeholder="Select Variant"
                  // value={selectVariant}
                >
                  {variantList?.map((variant) => (
                    <Select.Option
                      key={variant?.variantId}
                      value={variant?.variantId}
                    >
                      {variant.variantDesc}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="taxInclusive"
                label="Tax Inclusive"
                valuePropName="checked"
                // initialValue={false}
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="taxRate"
                label="Tax Rate"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Enter Tax Rate" disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="variantSize"
                label="Variant Size"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Enter Variant Size" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="unitPrice"
                label="Unit Price"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input type="number" placeholder="Enter Unit Price" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ marginRight: "10px" }}
            >
              {itemId ? "Update" : "Add"}
            </Button>
            <Button onClick={() => navigate("/menu-item-price")}>Cancel</Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default MenuItemForm;
