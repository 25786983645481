import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  // Input,
  Button,
  message,
  Select,
  // Checkbox,
  notification,
  Row,
  Col,
  DatePicker,
  Input,
  Switch,
} from "antd";
import FormBuilder from "antd-form-builder";
import {
  emailValidator,
  latitudeValidator,
  longitudeValidator,
} from "../../../lib/utils/form-validator";
import { companyState } from "../../../lib/stores/companyId";
import moment from "moment";
import "./page.css";

const MenuForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { itemId } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  // const [companyDropdown, setCompanyDropdown] = useState<any>([]);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  // const [companyTypes, setCompanyTypes] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDateData, setStartDateData] = useState<any>(null);
  const [endDateData, setEndDateData] = useState<any>(null);

  useEffect(() => {
    setBreadCrumb([
      { label: "Menu Master", href: "/dashboard" },
      { label: "Menu", href: "/menu" },
      {
        label: itemId ? "Edit Menu" : "Add Menu",
        href: itemId ? `/menu/${itemId}` : "/menu/add",
      },
    ]);
  }, [setBreadCrumb]);

  const fetchById = async () => {
    try {
      // const payLoad = {
      //   companyId: id,
      // };
      // replace endpoint with location id endpoint to get details
      const response = await get(`${apiEndpoints.addMenu}/${itemId}`);
      if (response.status === 200) {
        const initialValues = {
          ...response?.data,
          active: response?.data?.isActive === 1 ? true : false,
        };
        form.setFieldsValue(initialValues);
        const itemeditData = response?.data["0"];
        form.setFieldsValue(itemeditData);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (!location.pathname.includes("add")) {
      fetchById();
    }
  }, [location]);

  const onFinish = async (values: any) => {
    try {
      // Now use modifiedValues for submission
      // const payLoad: any = { ...values, companyId: companyId };
      const payLoad = {
        ...values,
      };
      setIsLoading(true);
      const endPoints = {
        updateDevice: "editMenu",
        addDevice: "addMenu",
      };

      const endPoint = itemId ? endPoints.updateDevice : endPoints.addDevice;
      const method = itemId ? put : post;

      // Use the correct template literal for constructing the API endpoint
      const apiUrl = itemId
        ? `${apiEndpoints[endPoint]}/${itemId}`
        : apiEndpoints[endPoint];

      // Await the API method with the correct URL and payload
      const response = await method(apiUrl, payLoad);
      if (response.status === 200) {
        notification.success({
          message: itemId ? "Updated Successfully" : "Created Successfully",
        });
        navigate("/menu");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error("Please fill in all fields");
  };

  return (
    <>
      <div
        className="filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={
            {
              /* Set initial values if any */
            }
          }
          className="item-form"
        >
          <Form.Item
            name="menuName"
            label="Menu Name"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Enter Menu Name" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ marginRight: "10px" }}
            >
              {itemId ? "Update Menu" : "Add Menu"}
            </Button>
            <Button onClick={() => navigate("/menu")}>Cancel</Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default MenuForm;
