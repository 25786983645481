import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  Button,
  message,
  Select,
  notification,
  Input,
  Switch,
  Row,
  Col,
} from "antd";
import { companyState } from "../../../lib/stores/companyId";
import "./page.css";

const OutletGroupForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { itemId } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Outlet Group", href: "/outlet-group" },
      {
        label: itemId ? "Edit Outlet Group" : "Add Outlet Group",
        href: itemId ? `/outlet-group/${itemId}` : "/outlet-group/add",
      },
    ]);
  }, [setBreadCrumb]);

  const fetchById = async () => {
    try {
      const response = await get(`${apiEndpoints.outletgroup}/${itemId}`);
      if (response.status === 200) {
        const initialValues = {
          ...response?.data,
          isActive: response?.data?.isActive === 1 ? true : false,
        };
        form.setFieldsValue(initialValues);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (!location.pathname.includes("add")) {
      fetchById();
    }
  }, [location]);

  const onFinish = async (values: any) => {
    try {
      const payLoad = {
        ...values,
        isActive: values?.isActive ? 1 : 0,
      };
      setIsLoading(true);
      const endPoints = {
        updateDevice: "outletgroup",
        addDevice: "outletgroup",
      };

      const endPoint = itemId ? endPoints.updateDevice : endPoints.addDevice;
      const method = itemId ? put : post;

      const apiUrl = itemId
        ? `${apiEndpoints[endPoint]}/${itemId}`
        : apiEndpoints[endPoint];

      const response = await method(apiUrl, payLoad);
      if (response.status === 200) {
        notification.success({
          message: itemId ? "Updated Successfully" : "Created Successfully",
        });
        navigate("/outlet-group");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
      notification.error({
        message: error?.response?.data?.message || "Error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.error({
      message: "Please fill in all fields",
    });
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        initialValues={{}}
        className="item-form"
      >
        {/* Use Row and Col for layout */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="outletName"
              label="Outlet Name"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Enter Outlet Name" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="outletDesc"
              label="Outlet Desc"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Enter Outlet Desc" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="outletTimings"
              label="Outlet Timings"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Enter Outlet Timings " />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="outletCity"
              label="Outlet City"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Enter Outlet City" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="outletPhoto"
              label="Outlet Photo"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Enter Outlet Photo" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="outletTinyURL"
              label="Outlet Tiny URL"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Enter Outlet Tiny URL" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="isActive"
              label="status"
              valuePropName="checked"
              // initialValue={false}
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ marginRight: "10px" }}
          >
            {itemId ? "Update" : "Add"}
          </Button>
          <Button onClick={() => navigate("/outlet-group")}>Cancel</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default OutletGroupForm;
