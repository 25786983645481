import React, { useEffect, useState } from "react";
import { Select, Tooltip, message, notification } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { del, deleteMethod, get, post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
import { useNavigate } from "react-router-dom";
import { companyState } from "../../../lib/stores/companyId";
import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import MapIcon from "../../../lib/components/MapLocator/page";
import { MenuState } from "../../../lib/stores/menuId";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const renderStatus = (value?: any, key?: any, record?: any) => {
  if (key === "userName") {
    if (record?.fcmDataID?.userName) {
      return (
        <Tooltip title={record.fcmDataID.userName}>
          <div>{record.fcmDataID.userName}</div>
        </Tooltip>
      );
    }
  }

  if (key === "locationId") {
    if (value !== null) {
      return <CopyClipBoard text={value} />;
    } else {
      return "NA";
    }
  }
  if (key === "locationAddress") {
    if (value !== null) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={value}>
            {/* <div>{value}</div> */}
            <div className="ellipsis-cell">{value}</div>
          </Tooltip>
          <MapIcon latitude={record?.latitude} longitude={record?.longitude} />
        </div>
      );
    } else {
      return "NA";
    }
  }
  if (key === "updatedAt") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const MenuItem: React.FC = () => {
  const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };
  const [menuList, setMenuList] = useState([]);
  const [selectMenu, setSelectMenu] = useState(null);
  const [dynamicMenuId, setMenuId] = useRecoilState(MenuState);

  // useEffect(())
  useEffect(() => {
    setBreadCrumb([
      { label: "Menu Master", href: "/dashboard" },
      { label: "Menu Item Price", href: "/menu-item-price" },
    ]);
  }, [setBreadCrumb]);

  const handleEdit = (record: any) => {
    navigate(`/menu-item-price/${record.itemVariantId}`);
    //console.log(record, "record");
  };

  const handleDeleteConfirmation = async (menuId: string, itemId: string) => {
    try {
      if (itemId) {
        const response = await deleteMethod(
          `${apiEndpoints.menuitempricegud}/${menuId}/${itemId}`
        );
        if (response.status === 200) {
          notification.success({
            message: "Successfully Deleted",
          });
          await fetchMenus();
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = (record: any) => {
    DiscardChangeModal(
      "Click OK to Confirm to Delete.",
      async () => {
        handleDeleteConfirmation(record?.menuId,record?.itemVariantId);
      },
      () => {},
      { title: "Are you sure you want to Delete ?" }
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Item ID",
      dataIndex: "itemId",
      key: "itemId",
      sorter: (a: any, b: any) =>
        (a?.itemId?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.itemId?.toString() ?? "").toLowerCase()),
      width: 50,
      // render: renderStatus,
      render: (value) => renderStatus(value, "menu"),
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      sorter: (a: any, b: any) =>
        (a?.itemName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.itemName?.toString() ?? "").toLowerCase()),
      width: 50,
      render: renderStatus,
    },
    {
      title: "Variant ID",
      dataIndex: "itemVariantId",
      key: "itemVariantId",
      sorter: (a: any, b: any) =>
        (a?.itemVariantId?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.itemVariantId?.toString() ?? "").toLowerCase()),
      width: 50,
      render: renderStatus,
    },
    {
      title: "Variant Name",
      dataIndex: "variantName",
      key: "variantName",
      sorter: (a: any, b: any) =>
        (a?.variantName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.variantName?.toString() ?? "").toLowerCase()),
      width: 50,
      render: renderStatus,
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      sorter: (a: any, b: any) =>
        (a?.unitPrice?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.unitPrice?.toString() ?? "").toLowerCase()),
      width: 50,
      render: renderStatus,
    },
    {
      title: "Sort Id",
      dataIndex: "sortId",
      key: "sortId",
      sorter: (a: any, b: any) =>
        (a?.sortId?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.sortId?.toString() ?? "").toLowerCase()),
      width: 50,
      render: renderStatus,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a: any, b: any) =>
        (a?.updatedAt?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.updatedAt?.toString() ?? "").toLowerCase()),
      width: 50,
      render: (value) => renderStatus(value, "updatedAt"),
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 30,
      render: (record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Tooltip title="Edit">
            <EditOutlined onClick={() => handleEdit(record)} />
          </Tooltip>{" "}
          &nbsp; &nbsp;&nbsp;
          <Tooltip title="Delete">
            <DeleteOutlined onClick={() => handleDelete(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  const fetchMenus = async () => {
    try {
      const response = await get(apiEndpoints.menus);
      if (response.status === 200) {
        setMenuList(response?.data);
        setSelectMenu(response?.data[0]?.menuId);
        if (dynamicMenuId) {
          setMenuId(dynamicMenuId);
          setSelectMenu(dynamicMenuId);
        } else {
          setMenuId(response?.data[0]?.menuId);
        }
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    fetchMenus();
  }, []);

  const fetchMenuItems = async (id) => {
    try {
      const response = await get(`${apiEndpoints.menuitemprice}/${id}`);
      if (response.status === 200) {
        setRowData(response?.data);
        localStorage.setItem("menuId", response?.data[0]?.menuId);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    if (selectMenu) {
      fetchMenuItems(selectMenu);
    }
  }, [selectMenu]);

  const handleChange = async (value) => {
    await fetchMenuItems(value);
    setSelectMenu(value);
    setMenuId(value);
    localStorage.setItem("menuId", value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <label>Select Menu:</label>
        <Select
          onChange={handleChange}
          placeholder="Select Menu"
          style={{ width: "11rem" }}
          value={selectMenu}
        >
          {menuList.map((location: any) => (
            <Select.Option key={location?.menuId} value={location?.menuId}>
              {location.menuName}
            </Select.Option>
          ))}
        </Select>
      </div>
      <CommonTable
        routePath="/menu-item-price/add"
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={fetchMenus}
      />
    </>
  );
};

export default MenuItem;
